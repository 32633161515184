import Logo from '../assets/fedworks_logo_horizontal_100.webp'
import Theme from './theme'
const FedworksTheme: Theme = {
    images: {
        logo: Logo,
    },
    colors: {
        brand: '#00868B',
        secondary: '#692068',
        text: 'black',
        lightText: 'white',
        parallaxBackground: '#00868B55',
    },
    fonts: {
        titleFont: '',
        titleFontSize: '',
        bodyFont: '',
        bodyFontSize: '',
    },
}

export default FedworksTheme
