import Logo from '../assets/fedworks_logo_horizontal_100.webp'
import Theme from './theme'

const GMWorkforceTheme: Theme = {
    images: {
        logo: Logo,
    },
    colors: {
        brand: '#68C8D0',
        secondary: '#5E2A6F',
        text: 'black',
        lightText: 'white',
        parallaxBackground: '#68C8D068',
    },
    fonts: {
        titleFont: '',
        titleFontSize: '',
        bodyFont: '',
        bodyFontSize: '',
    },
}

export default GMWorkforceTheme
