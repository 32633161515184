import { useAuth0 } from '@auth0/auth0-react'
import { Person } from '@mui/icons-material'
import { alpha, Button, Grid, Paper, Stack, Typography } from '@mui/material'
import { FunctionComponent } from 'react'

import Colors from '../../assets/colors'
import Logo2 from '../../assets/fedworks_logo_horizontal_100.webp'
import Logo from '../../assets/fedworks_logo_portrait.webp'
import Image from '../../assets/stock_about.webp'

const AboutPage: FunctionComponent = () => {
    const { loginWithRedirect } = useAuth0()
    const navigate = (location: string) => {
        window.history.pushState(null, null, location)
        window.location.replace(location)
    }

    return (
        <Stack height={'100vh'}>
            <Grid
                container
                alignItems={'center'}
                paddingY={2}
                paddingX={4}
                border={'2px solid'}
                borderColor={Colors.secondary.a20}
                borderTop={0}>
                <Grid item xs={4}>
                    <Button
                        variant='contained'
                        title='Back'
                        sx={{
                            backgroundColor: 'var(--primary-blue-color)',
                        }}
                        onClick={() => navigate('/')}>
                        Back
                    </Button>
                </Grid>
                <Grid item xs={4} justifyContent={'center'} display={'flex'}>
                    <img src={Logo2} height={'70px'} />
                </Grid>
                <Grid item xs={4} justifyContent={'end'} display={'flex'}>
                    <Button
                        variant='contained'
                        title='Login'
                        onClick={() => {
                            loginWithRedirect()
                        }}
                        sx={{
                            backgroundColor: Colors.primary.main,
                        }}
                        startIcon={<Person />}>
                        Login
                    </Button>
                </Grid>
            </Grid>

            <Grid
                container
                borderLeft={'2px solid'}
                borderRight={'2px solid'}
                borderColor={Colors.secondary.a20}
                alignItems={'center'}
                justifyContent={'center'}
                flexGrow={1}
                sx={{
                    backgroundImage: `url(${Image})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                }}>
                <Grid
                    item
                    xs={12}
                    md={9}
                    lg={8}
                    xl={7}
                    sx={{
                        transition: 'all 0.5s ease-out',
                    }}>
                    <Grid container padding={8} spacing={4}>
                        <Grid item xs={12}>
                            <Paper>
                                <Typography
                                    align='center'
                                    color='primary.light'
                                    fontSize={48}
                                    fontWeight={500}>
                                    About Us
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} xl={6}>
                            <Paper
                                elevation={4}
                                sx={{
                                    backgroundColor: alpha('#fff', 0.96),
                                    padding: 4,
                                    height: 1,
                                    boxSizing: 'border-box',
                                }}>
                                <Typography
                                    variant='h4'
                                    color={Colors.primary.main}
                                    letterSpacing={2}
                                    marginBottom={4}>
                                    Fedworks
                                </Typography>
                                <Typography color='text.secondary'>
                                    Our rota management platform is designed to
                                    simplify workforce scheduling and streamline
                                    operations. With powerful features like
                                    real-time availability tracking, automated
                                    booking processes, and seamless
                                    communication tools, it helps organizations
                                    save time and improve efficiency. Whether
                                    managing shifts, coordinating coverage, or
                                    planning for the future, our intuitive
                                    system ensures everything runs smoothly,
                                    allowing teams to focus on delivering
                                    exceptional outcomes.
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} xl={6}>
                            <Paper
                                elevation={4}
                                sx={{
                                    backgroundColor: alpha('#fff', 0.96),
                                    padding: 4,
                                    height: 1,
                                    boxSizing: 'border-box',
                                }}>
                                <Typography
                                    variant='h4'
                                    color={Colors.secondary.main}
                                    letterSpacing={2}
                                    marginBottom={4}>
                                    Our Team
                                </Typography>
                                <Typography color='text.secondary'>
                                    This innovative solution is a result of a
                                    collaborative partnership between
                                    KMTComputing Ltd and Bolton GP Federation.
                                    By combining cutting-edge technology
                                    expertise with deep healthcare insights,
                                    we’ve created a platform tailored to the
                                    needs of modern organizations. Together, we
                                    are redefining workforce management to
                                    better support professionals and their
                                    teams.
                                </Typography>
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Stack
                direction={'row'}
                justifyContent={'center'}
                alignItems={'center'}
                paddingY={2}
                paddingX={4}
                border={'2px solid'}
                borderColor={Colors.secondary.a20}
                borderBottom={0}>
                <img src={Logo} height={'70px'} />
            </Stack>
        </Stack>
    )
}

export default AboutPage
