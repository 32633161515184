import { useAuth0 } from '@auth0/auth0-react'
import { Person } from '@mui/icons-material'
import {
    alpha,
    Box,
    Button,
    Grid,
    Paper,
    Stack,
    Typography,
} from '@mui/material'
import { FunctionComponent } from 'react'
import Colors from '../../assets/colors'
import { default as Logo2 } from '../../assets/fedworks_logo_horizontal_100.webp'
import Image from '../../assets/stock_landing.webp'

const Landing: FunctionComponent = () => {
    const { loginWithRedirect } = useAuth0()
    // let navigate = useNavigate()
    const navigate = (location: string) => {
        window.history.pushState(null, null, location)
        window.location.replace(location)
    }

    return (
        <Stack height={'100vh'}>
            <Grid
                container
                alignItems={'center'}
                paddingY={2}
                paddingX={4}
                border={'2px solid'}
                borderColor={Colors.secondary.a20}
                borderTop={0}>
                <Grid item xs={4}>
                    <Button
                        variant='text'
                        color='primary'
                        onClick={() => navigate('/about')}>
                        About
                    </Button>
                    <Button
                        variant='text'
                        color='primary'
                        onClick={() => navigate('/gdpr')}>
                        Privacy Policy
                    </Button>
                </Grid>
                <Grid item xs={4} justifyContent={'center'} display={'flex'}>
                    <img src={Logo2} height={'70px'} />
                </Grid>
                <Grid item xs={4} justifyContent={'end'} display={'flex'}>
                    <Button
                        variant='contained'
                        title='Login'
                        onClick={() => {
                            loginWithRedirect()
                        }}
                        sx={{ backgroundColor: Colors.primary.main }}
                        startIcon={<Person />}>
                        Login
                    </Button>
                </Grid>
            </Grid>

            <Grid
                container
                borderLeft={'2px solid'}
                borderRight={'2px solid'}
                borderColor={Colors.secondary.a20}
                alignItems={'center'}
                justifyContent={'flex-end'}
                flexGrow={1}
                sx={{
                    backgroundImage: `url(${Image})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                }}>
                <Grid
                    item
                    xs={12}
                    md={9}
                    lg={8}
                    xl={7}
                    padding={8}
                    sx={{
                        transition: 'all 0.5s ease-out',
                    }}>
                    <Stack
                        component={Paper}
                        sx={{ backgroundColor: alpha('#fff', 0.96) }}
                        elevation={4}
                        padding={4}
                        spacing={4}>
                        <Typography
                            color='primary.main'
                            fontSize={48}
                            fontWeight={400}>
                            Simplify Rota Management:
                            <Box
                                component='span'
                                color='primary.light'
                                fontWeight={400}>
                                {' '}
                                Save Time, Reduce Stress
                            </Box>
                        </Typography>

                        <Stack spacing={2} paddingBottom={4}>
                            <Typography variant='h5' letterSpacing={2}>
                                Fedworks: Workforce Management, Simplified
                            </Typography>
                            <Typography color='text.secondary'>
                                Effortlessly manage schedules with our intuitive
                                rota system designed for professionals.
                                Streamline bookings, track availability, and
                                ensure seamless communication within your team.
                                Our platform empowers you to reduce admin time,
                                minimize gaps in coverage, and focus on
                                delivering exceptional service. With real-time
                                updates and smart automation, you'll always stay
                                ahead. Experience a smarter, more connected way
                                to manage your workforce today.
                            </Typography>
                        </Stack>
                    </Stack>
                </Grid>
            </Grid>

            {/* <Stack
                direction={'row'}
                justifyContent={'center'}
                alignItems={'center'}
                paddingY={2}
                paddingX={4}
                border={'2px solid'}
                borderColor={Colors.secondary.a20}
                borderBottom={0}></Stack> */}
        </Stack>
    )
}

export default Landing
